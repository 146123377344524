<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">通知設定センサ</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-data-table
                class="elevation-0"
                :headers="headers"
                :items="userDevices"
                :loading="loading"
              >
                <template v-slot:[`item.device_name`]="{ item }">
                  {{ deviceName(item.device_id) }}
                </template>
                <template v-slot:[`item.lastest_checked_at`]="{ item }">
                  {{ item.lastest_checked_at | moment }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="close">
          閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { getUser } from '@/api'
import { MAIN_COLOR, TIME_IS_ZERO } from '@/constants'

export default {
  name: 'UserDeviceList',
  props: {
    dialog: Boolean,
    id: String
  },
  data() {
    return {
      mainColor: MAIN_COLOR,
      loading: false,
      headers: [
        { text: 'センサID', value: 'device_id' },
        { text: 'センサ名', value: 'device_name' }
      ],
      userDevices: []
    }
  },
  computed: {
    ...mapGetters(['deviceById'])
  },
  async created() {
    this.loading = true
    await this.initUserDevices()
    this.loading = false
  },
  methods: {
    async initUserDevices() {
      let res
      try {
        res = await getUser(this.id)
      } catch (e) {
        console.error(e)
        alert('サーバーエラーが発生しました。')
        return
      }
      this.userDevices = res.user_devices
    },
    deviceName(deviceId) {
      const device = this.deviceById(deviceId)
      return device !== undefined ? device.name : ''
    },
    close() {
      this.$emit('close')
    }
  },
  filters: {
    moment(date) {
      if (date === TIME_IS_ZERO) {
        return '--'
      }
      return moment(date).format('YYYY/MM/DD')
    }
  }
}
</script>
