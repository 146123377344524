<template>
  <v-dialog
    v-model="dialog"
    max-width="680"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">編集</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="item.id"
                  label="ID"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="item.name"
                  label="ユーザー名"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="isGeneralGroup(item.group) && isAdminGroup">
                <v-select
                  v-model="item.group"
                  label="グループ"
                  :items="groupNamesByGeneral"
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col v-else>
                <v-text-field
                  :value="displayGroupName(item.group)"
                  label="グループ"
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <header>グループ設定</header>
                <p class="caption text--secondary">
                  「メンバー」に登録すると、各センサの設定画面において<br />
                  「メンバーにのみテスト送信」を選択した際に通知が有効になります。<br />
                  ※「メンバーにのみテスト送信」機能は定期点検などに使用します。<br />
                  ※「メンバー」は「一般」が受ける通知を全て受けることができます。
                </p>
                <v-radio-group v-model="item.role">
                  <v-radio
                    v-for="role in roles"
                    :key="role.name"
                    :label="role.label"
                    :value="role.name"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                ユーザーを削除する場合は、以下を無効にすると一覧画面に削除ボタンが表示されます。
                <v-switch v-model="item.enabled" label="無効 / 有効"></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="close">
            キャンセル
          </v-btn>
          <v-btn depressed dark :color="mainColor" @click="save">保存</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { putUser } from '@/api'
import {
  MAIN_COLOR,
  USER_ROLE_LIST,
  USER_ROLE_GENERAL,
  USER_ROLE_ADMIN
} from '@/constants'

export default {
  name: 'UserEditor',
  props: {
    dialog: Boolean,
    userId: String
  },
  data() {
    return {
      mainColor: MAIN_COLOR,
      valid: true,
      roles: USER_ROLE_LIST
    }
  },
  computed: {
    ...mapGetters([
      'userById',
      'groups',
      'groupNames',
      'groupNamesByGeneral',
      'isAdminGroup'
    ]),
    title() {
      return '編集'
    },
    item() {
      return Object.assign({}, this.userById(this.userId))
    }
  },
  methods: {
    ...mapActions(['updateUser', 'setFullscreenLoading']),
    close() {
      this.$refs.form.resetValidation()
      this.$emit('close')
    },
    async save() {
      if (!this.$refs.form.validate()) {
        return
      }
      this.setFullscreenLoading(true)
      try {
        const res = await putUser(this.item)
        this.updateUser(res.user)
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      }
      this.close()
      this.setFullscreenLoading(false)
    },
    isGeneralGroup(id) {
      const group = this.groups.find(g => g.id == id)
      if (!group) {
        return true
      }
      return group.role === USER_ROLE_GENERAL
    },
    displayGroupName(id) {
      const group = this.groupNames.find(g => g.id == id)
      if (!group) {
        return ''
      }
      return group.name
    },
    displayRole(role) {
      return role === USER_ROLE_ADMIN ? '管理者' : '一般'
    }
  }
}
</script>
