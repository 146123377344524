<template>
  <v-main>
    <v-container>
      <v-row>
        <v-col>
          <h1>ユーザー</h1>
          <p class="caption">
            ※KAMEKER管理担当のユーザーの方は右側の編集ボタンからグループ設定を「メンバー」に設定してください。
          </p>
        </v-col>
      </v-row>
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="検索"
            single-line
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-select
            v-model="searchByRole"
            label="一般 / メンバー 絞り込み"
            :items="roleNames"
            style="top: 10px"
            clearable
          ></v-select>
          <v-spacer></v-spacer>
          <v-select
            v-model="searchByGroup"
            label="グループで絞り込み"
            :items="groupNames"
            item-text="name"
            item-value="id"
            style="top: 10px"
            clearable
          ></v-select>
          <v-spacer></v-spacer>
          <v-btn @click.stop="downloadCsv" plain>
            全ユーザーの<br />CSVダウンロード
            <v-icon small class="ml-2">
              mdi-download
            </v-icon>
          </v-btn>
          <v-divider vertical></v-divider>
          <v-btn icon @click="doLoad(false)" class="ml-2">
            <v-progress-circular
              v-show="loading"
              :indeterminate="loading"
            ></v-progress-circular>
            <v-icon v-show="!loading">mdi-reload</v-icon>
          </v-btn>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :search="search"
          :custom-filter="doSearch"
        >
          <template v-slot:[`item.picture`]="{ item }">
            <v-avatar height="32px" width="32px" min-width="32px">
              <img
                v-if="item.picture !== ''"
                :src="item.picture"
                :alt="item.name"
              />
              <v-icon v-else>mdi-account-circle</v-icon>
            </v-avatar>
          </template>
          <template v-slot:[`item.group`]="{ item }">
            {{ displayGroupName(item.group) }}
          </template>
          <template v-slot:[`item.role`]="{ item }">
            {{ displayRoleName(item.role) }}
          </template>
          <template v-slot:[`item.lineOfficialAccount`]="{ item }">
            {{ displayLineOffcialAccount(item.group) }}
          </template>
          <template v-slot:[`item.userDevice`]="{ item }">
            <v-icon small class="mr-2" @click="open(item.id)">
              mdi-bell
            </v-icon>
          </template>
          <template v-slot:[`item.enabled`]="{ item }">
            <v-chip
              :color="item.enabled ? 'success' : 'grey'"
              label
              small
              outlined
              >{{ item.enabled ? `有効` : '無効' }}
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon small class="mr-2" @click.stop="doEdit(item.id)">
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="!item.enabled"
              color="red"
              small
              @click="doDelete(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            現在登録されたユーザーはいません。
          </template>
        </v-data-table>
      </v-card>
      <user-device-list
        v-if="currentUserId != ''"
        :dialog="dialog"
        :id="currentUserId"
        @close="close"
      ></user-device-list>
      <user-editor
        :dialog="userDialog"
        :userId="editUserId"
        @close="close"
      ></user-editor>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  getUsers,
  getDevices,
  getGroups,
  deleteUser,
  getUsersCsvData
} from '@/api'
import { downloadCSVByResponse } from '@/middleware/api'
import {
  MAIN_COLOR,
  GROUP_ROLE_CUSTOM,
  DEFAULT_LINE_OFFICIAL_ACCOUNT_NAME,
  IS_CLOSED
} from '@/constants'
import UserDeviceList from '@/components/user-device-list'
import UserEditor from '@/components/user-editor'

export default {
  name: 'UserIndex',
  components: {
    UserDeviceList,
    UserEditor
  },
  data() {
    return {
      mainColor: MAIN_COLOR,
      loading: false,
      search: '',
      searchByGroup: '',
      searchByRole: '',
      dialog: false,
      userDialog: false,
      headers: [
        { text: 'ユーザー名', value: 'name', sortable: true },
        {
          text: 'アバター',
          value: 'picture',
          sortable: false
        },
        {
          text: 'グループ',
          value: 'group',
          sortable: true
        },
        {
          text: '一般 / メンバー',
          value: 'role',
          sortable: true
        },
        {
          text: 'LINE公式アカウント',
          value: 'lineOfficialAccount',
          sortable: false
        },
        {
          text: '有効 / 無効',
          value: 'enabled',
          align: 'center',
          sortable: true
        },
        {
          text: '通知設定',
          value: 'userDevice',
          align: 'center',
          sortable: false
        },
        {
          text: '編集 / 削除',
          value: 'action',
          align: 'center',
          sortable: false
        }
      ],
      currentUserId: '',
      editUserId: '',
      roleNames: [
        {
          text: '',
          value: ''
        },
        {
          text: '一般',
          value: 'general'
        },
        {
          text: 'メンバー',
          value: 'admin'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['users', 'groupNames', 'groups', 'isAdminGroup']),
    items() {
      let filtered = this.users
      if (this.searchByRole) {
        filtered = filtered.filter(u => u.role === this.searchByRole)
      }
      if (this.searchByGroup) {
        filtered = filtered.filter(u => u.group === this.searchByGroup)
      }
      return filtered
    }
  },
  async created() {
    this.doLoad(true)
  },
  methods: {
    ...mapActions([
      'setUsers',
      'updateUser',
      'deleteUser',
      'setDevices',
      'setGroups',
      'setFullscreenLoading'
    ]),
    async doLoad(storeCheck) {
      if (this.loading) {
        return
      }
      this.loading = true
      await this.initUser(storeCheck)
      this.loading = false
      this.initDevice(storeCheck)
      await this.initGroup(storeCheck)
    },
    async initUser(storeCheck) {
      if (storeCheck && this.$store.getters.users.length > 0) {
        return
      }
      let res
      try {
        res = await getUsers()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.setUsers(res.users)
    },
    async initDevice(storeCheck) {
      if (storeCheck && this.$store.getters.devices.length > 0) {
        return
      }
      let res
      try {
        res = await getDevices()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.setDevices(res.devices)
    },
    async initGroup(storeCheck) {
      if (storeCheck && this.$store.getters.groups.length > 0) {
        return
      }
      let res
      try {
        res = await getGroups()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
        return
      }
      this.setGroups(res.groups)
    },
    doEdit(id) {
      this.editUserId = id
      this.userDialog = true
    },
    async doDelete(user) {
      const message = user.enabled
        ? `ID: ${user.id} を無効にしてもよろしいですか？\n削除は無効にしたあとに行うことができます。`
        : `ID: ${user.id} を削除してもよろしいですか？`
      if (!confirm(message)) {
        return
      }

      this.setFullscreenLoading(true)
      try {
        await deleteUser(user.id)
        this.deleteUser(user)
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      }
      this.setFullscreenLoading(false)
    },
    async open(id) {
      this.currentUserId = id
      this.dialog = true
    },
    close() {
      this.currentUserId = ''
      this.dialog = false
      this.userDialog = false
    },
    displayGroupName(groupId) {
      const group = this.groupNames.find(g => g.id == groupId)
      if (!group) {
        return ''
      }
      return group.name
    },
    displayRoleName(role) {
      return this.isAdminRole(role) ? 'メンバー' : '一般'
    },
    displayLineOffcialAccount(groupId) {
      const group = this.groups.find(g => g.id == groupId)
      if (!group) {
        return ''
      }
      if (IS_CLOSED || group.role === GROUP_ROLE_CUSTOM) {
        return group.line_official_account_name
      }
      return DEFAULT_LINE_OFFICIAL_ACCOUNT_NAME
    },
    isAdminRole(role) {
      return role === 'admin'
    },
    doSearch(value, search, item) {
      return (
        item.name.includes(search) ||
        this.displayLineOffcialAccount(item.group).includes(search)
      )
    },
    async downloadCsv() {
      this.setFullscreenLoading(true)
      let res = null
      try {
        res = await getUsersCsvData()
      } catch (e) {
        console.error(e)
        alert('サーバエラーが発生しました')
      } finally {
        this.setFullscreenLoading(false)
      }
      if (res === null) {
        return
      }
      // csvダウンロード処理
      downloadCSVByResponse(res)
    }
  }
}
</script>
